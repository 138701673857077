import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MintAccessRole } from '@bryllyant/mint-ngx';

import { AppIdentifier, Config, ErrorPageType } from '@fynvana/common';
import { ErrorPageComponent } from '@fynvana/components';
import { RoleGuard } from '@fynvana/core';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          loadChildren: () =>
            import('./public/public.module').then((m) => m.PublicModule),
          data: { preload: true },
        },
        {
          path: '',
          canActivate: [RoleGuard],
          loadChildren: () =>
            import('./main/main.module').then((m) => m.MainModule),
          data: {
            preload: true,
            app: AppIdentifier.Taxpayer,
            role: MintAccessRole.User,
          },
        },
        {
          path: 'auth',
          loadChildren: () => import('@fynvana/auth').then((m) => m.AuthModule),
          data: {
            preload: true,
            app: AppIdentifier.Taxpayer,
            role: MintAccessRole.User,
          },
        },
        {
          path: 'error',
          component: ErrorPageComponent,
          data: { preload: false },
        },
        {
          path: '**',
          component: ErrorPageComponent,
          data: { type: ErrorPageType.PageNotFound },
        },
      ],
      Config.ROUTE_OPTIONS,
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
