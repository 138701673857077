import { MintConfigOptions } from '@bryllyant/mint-ngx';
import { _default } from './default';

export const environment: MintConfigOptions = {
  env: 'development',
  configs: [
    {
      _default: _default,
    },
    {
      development: {
        websiteBaseUrl: 'https://dev.fynvana.com/',
        adminBaseUrl: 'https://admin.dev.fynvana.com/',
        taxpayerBaseUrl: 'https://taxpayer.dev.fynvana.com/',
        docsBaseUrl: 'https://docs.dev.fynvana.com/',
        clientBaseUrl: 'https://client.dev.fynvana.com/',

        api: {
          baseUrl: 'https://api.dev.fynvana.com/',
        },

        analytics: {},

        logger: {
          level: 'debug',
        },

        services: {},
      },
    },
  ],
};
