import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MintAuthService, MintLogger } from '@bryllyant/mint-ngx';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { BaseController, BaseService, LogoutType } from '@fynvana/common';
import { lastValueFrom } from 'rxjs';

const logger = new MintLogger('AppComponent');

@Component({
  selector: 'fynvana-root',
  template: ` <router-outlet></router-outlet> `,
})
export class AppComponent extends BaseController implements OnInit {
  constructor(
    public baseService: BaseService,
    private readonly httpClient: HttpClient,
    private readonly authService: MintAuthService,
    private readonly idle: Idle,
  ) {
    super(baseService);
  }

  async ngOnInit() {
    this.baseService.seoService.initGoogleTagManager(
      this.baseService.config.analytics?.google?.trackingId,
    );

    await this.initApiVersion();
    this.configureIdleLogout();
  }

  private async initApiVersion() {
    const apiVersionData = await lastValueFrom(
      this.httpClient.get<{ api_version: string; hash: string }>(
        `${this.baseService.config.api.baseUrl}version`,
      ),
    );

    this.baseService.config.api.apiVersion =
      apiVersionData?.api_version ?? 'dev';
  }

  private configureIdleLogout() {
    this.idle.setIdle(this.baseService.config.auth.idle.idleStart as number);
    this.idle.setTimeout(this.baseService.config.auth.idle.timeout as number);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onIdleStart.subscribe(() => logger.info('User is idle'));
    this.idle.onIdleEnd.subscribe(() => logger.info('User is no longer idle'));
    this.idle.onTimeout.subscribe(async () => {
      logger.info('User has timed out, logging out...');
      await this.baseService.router.navigate(['/auth/logout'], {
        queryParams: {
          type: LogoutType.UserTimeOut,
          redirect: this.baseService.router.routerState.snapshot.url,
        },
      });
    });
    this.authService.authenticated$.subscribe((isAuthed) =>
      isAuthed ? this.idle.watch() : this.idle.stop(),
    );
  }
}
