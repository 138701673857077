import { MintFormField, MintFormFieldOption } from '@bryllyant/mint-ngx';

export class Autocomplete extends MintFormField {
  placeholder: string;
  loading: false | 'search' | 'scroll';
  options: MintFormFieldOption[] = [];
  maxLength: number;

  constructor(model: Partial<Autocomplete>) {
    super(model);

    if (typeof this.loading === 'undefined') {
      this.loading = false;
    }
  }
}
