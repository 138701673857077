export enum DocusignSessionEndState {
  SigningComplete = 'signing_complete',
  Cancel = 'cancel',
  Decline = 'decline',
  Exception = 'exception',
  FaxPending = 'fax_pending',
  SessionTimeout = 'session_timeout',
  TtlExpired = 'ttl_expired',
  ViewingComplete = 'viewing_complete',
}

export interface DocusignSessionEndPayload {
  type: string;
  sessionEndType: DocusignSessionEndState;
  returnUrl: string;
}
